html {
	height: 100%;
}

/*
 * Declare fonts
 */
@font-face {
	font-family: "Circular";
	src: url("./fonts/CircularXXWeb-Book.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Circular";
	src: url("./fonts/CircularXXWeb-Medium.woff") format("woff");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

/* @font-face {
	font-family: "Circular";
	src: url("./fonts/CircularXXWeb-Regular.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
} */

body {
	margin: 0;
	height: 100%;
	padding-top: env(safe-area-inset-top), 0;
	padding-right: env(safe-area-inset-right), 0;
	padding-left: env(safe-area-inset-left), 0;
	padding-bottom: env(safe-area-inset-bottom), 0;

	scrollbar-width: thin;
	--fonts: 'Circular', 'Inter', 'Arial';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	font-family: var(--fonts);

	/* Button styles */
	--primary-button: #FDCF2B;
	--primary-button-hovered: #F1C423;
	--negative-action-text: #58627A;
	/* Color Tints */
	--firebrick-text: #B22222;
	--firebrick-background: #b2222226;
	--aqua-text: #009999;
	--aqua-background: #0099991a;
	--orange-text: #DE4504;
	--orange-background: #FAE1C4;
	--teal-text: #33FFAC;
	--teal-background: #E5FFF5;
	--purple-text: #693BCC;
	--purple-background: #E7E1F4;
	--green-text: #20752E;
	--checkmark-green: #219653;
	--green-background: #EBF2EC;
	--pink-text: #FF3387;
	--pink-background: #FFE5F0;
	--blue-text: #2860FE;
	--blue-background: #E8EEFF;
	--neon-green-text: #7A8C2C;
	--neon-green-background: #EAF0D0;
	--positive-green: #005914;
	--text-background: #d3d3d3;
	--grey-border: #efefef;
	--discord-grey: #2e3136;
	--discord-light-grey: #424549;
	--discord-title-grey: #58627a;
	--discord-embed-grey: #36393e;
	--discord-text: #DCDDDE;
	--discord-purple: #7289da;
	--alert-red: #EB5757;
	--info-blue: #2F80ED;
	--selected-nav-color: #3851da;

	/* Notification Colors */
	--notification-info-background: #ecf2ff;
	--notification-info-text: #3568e3;
	--notification-danger-background: #ffc4c4;
	--notification-danger-text: #892b2b;

	/* Company Colors */
	--primary: #FFD952;
	--background: #FFFFFF;
	--airfoil-grey: #E5E5E5;
	--airfoil-blue: #151E34;
	--airfoil-green: #34C759;
	--airfoil-purple: #4F46E5;
	--nav-color: rgba(225, 222, 211, 0.4);
 
	/* Retention Graph */
	--retention-data-header: #fffde6;
	--retention-data-cell: #426ca5;
	--close-icon: #e6ebec;

	/* Text Colors */
	--subtext-grey: #58627a;

	/* --blue: #425a7d; */
	--blue: #050948;
	--light-button-blue: #d3e5ff;
	--accent-blue: #d8fafb;
	--accent: #fc6565;
	--danger: #971c1c;
	--cancel: #ec4f42;
	--cream: #f2e9e1;
	--white: #FFFFFF;
	--lighter-grey: #f8f8f8;
	--light-grey: #e4e4e4;
	--grey: #aaaaaa;
	--dark-grey: #4F4F4F;
	--blue-grey: #adb3c0;
	--off-white: #f5f5f5;
	--bottom-border-grey: #f3f4f6;
	--option-background: #f4f6f9;
	--light-grey-border: #e0e0e0;
	--grey-divider: #f0f0f0;
	--off-white: #dadce3;
	--grey-tab-border: #e6e7eb;
	--info-grey: #D7D7D7;
	--email-icon-background: #767676;
	--icon-grey: #9ba2b2;
	--select-active: #B2D4FF;
	--select-focused: #DEEBFF;
	--status-label: #828282;
	--back-button-grey: #f9f9f9;
	--search-input-background: #fafafa;
	--side-bar-border-right: #fafbfb;
	--side-bar-background: #f8f9fa;
	--top-nav-menu-border-bottom: #e1e3e8;
	--customized-message-background: #f7f7f7;
	--insights-grey-wrapper: #e9e9e9;
	--selected-grey-background: #f6f6f6;
	--date-range-selector-color: #8f8f91;
	--button-hover-purple: #4f44e0;
	--hover-expandable-cell: rgba(79, 68, 224, 0.06);
	--slack-mention-background: #f7e9ba;
	--slack-mention-text: #2f629e;
  --status-text: #635BFF;
  --status-background: #635BFF1F;

	/* Shades of Company Colors */
	--dark-blue: #03062e;
	--light-blue: #0f1cd8;

	/* box shadow highlight */
	--tile-shadow: #00000014;
	--no-mobile-access-shadow: #78787814;
	--notification-shadow: #00000033;
	--email-preview-shadow: #cacaca40;
	--preview-shadow: #00000040;
	--mobile-submit-shadow: #0000001f;

	/* icon colors */
	--twitter-icon: #1b9cef;
	--linkedin-icon: #0177b5;

	background: #fbfbfb;
	color: var(--blue);

	/* Onix Colors */
	--Main-05: #F3F4F5;
	--Main-10: #E8E8EB;
	--Main-15: #DCDDE1;
	--Main-60: #737885;
	--Main-100: #151E34;
	--Purple-40: #CEBFF7;
	--Purple-100: #845FEA;
	--Yellow-10: #FFFAEA;
	--Yellow-100: #FDCF2B;
  --hide-button-color: rgba(115, 120, 133, 1);
  --onboard-background: rgba(133, 96, 235, 0.16);
  --onboard-arrow: rgba(208, 210, 214, 1);
  --gray-color: rgba(115, 120, 133, 0.6);
  --gray-color-2: rgba(232, 232, 235, 1);
  --gray-student-button: rgba(243, 244, 245, 1);
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

::selection {
	background: var(--primary);
	color: var(--blue);
}

::-webkit-scrollbar-corner {
	background: transparent;
}

::-webkit-scrollbar {
	width: 8px;
}

::-webkit-scrollbar-track {
	box-shadow: inset 0 0 15px 15px transparent;
	border: solid 4px transparent;
}

::-webkit-scrollbar-thumb {
	/* box-shadow: inset 0 0 15px 15px #D7D7D7; */
	border: solid 4px transparent;
	border-radius: 20px;
	background-color: #D7D7D7;
}

#root {
	height: 100%;
	overflow: hidden;
}
